$(document).on('turbolinks:load', () => {
  var evaluateIhcTestTypes = function() {

    $('input.check_boxes[name*="[ihc_tests][]"]').map((index, element) => {
      shouldShow = $(element).prop('checked')
      testTypeName = element.value.toLowerCase().replace(/[^a-z0-9_]/g, '_');

      $('input[name*="[ihc_test_result_' + testTypeName  + '"]').parent().toggle(shouldShow)
    });
  }

  $('input.check_boxes[name*="[ihc_tests][]"]').on('change', (evt) => {
    evaluateIhcTestTypes();
  })

  evaluateIhcTestTypes();
})

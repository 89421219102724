$(document).on('turbolinks:load', () => {
  $('.specimen_channel_config_checkbox').on('change', (evt) => {
    checkbox = $(evt.target)

    labelInput = $('#' + 'pcr_kit_machine_channel_config_specimen_channels_' + checkbox.data().channel + '_label')
    labelInput.prop(
      'disabled',
      !checkbox.prop('checked')
    )
  })

  $('.control_channel_config_checkbox').on('change', (evt) => {
    checkbox = $(evt.target)

    labelInput = $('#' + 'pcr_kit_machine_channel_config_control_channels_' + checkbox.data().channel + '_label')
    labelInput.prop(
      'disabled',
      !checkbox.prop('checked')
    )
  })
})

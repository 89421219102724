$(document).on('turbolinks:load', () => {
  $('.add-professional-specialty-button').on('click', (ev) => {
    ev.preventDefault()
    var lastSpecialtyField = $('input[name="physician[specialties][]"]:last-of-type')
    var cloned = lastSpecialtyField.clone()
    cloned.val("")
    lastSpecialtyField.after(cloned)
    cloned.focus()
  })

  $('.add-professional-subspecialty-button').on('click', (ev) => {
    ev.preventDefault()
    var lastSubspecialtyField = $('input[name="physician[subspecialties][]"]:last-of-type')
    var cloned = lastSubspecialtyField.clone()
    cloned.val("")
    lastSubspecialtyField.after(cloned)
    cloned.focus()
  })
})

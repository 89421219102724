import Sortable from "sortablejs";

$(document).on("turbolinks:load", () => {
  $(".add-option-button").on("click", (ev) => {
    ev.preventDefault();
    var lastOptionField = $(
      'input[name="target_data_field[options][]"]:last-of-type',
    );
    var cloned = lastOptionField.clone();
    cloned.val("");
    lastOptionField.after(cloned);
    cloned.focus();
  });

  let targetDataFieldsSortable = document.getElementById(
    "target-data-fields-sortable",
  );

  if (targetDataFieldsSortable != null) {
    Sortable.create(targetDataFieldsSortable, {
      animation: 100,
      ghostClass: "table-info",
      handle: ".sortable-handle",
      onEnd: (evt) => {
        if (evt.newIndex != evt.oldIndex) {
          let targetDataFieldId = evt.item.dataset.targetDataFieldId;
          let testTypeId = evt.item.dataset.testTypeId;
          let url = `/admin/test_types/${testTypeId}/target_data_fields/${targetDataFieldId}/update_position`;

          $.post(url, {
            position: evt.newIndex,
          });
        }
      },
    });
  }
});

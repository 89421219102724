$(document).on("turbolinks:load", () => {
  $(".child-package-products").on(
    "cocoon:after-insert",
    (e, insertedItem, originalEvenet) => {
      $(insertedItem).find(".auto-select2").select2({
        theme: "bootstrap4",
      });
    },
  );

  var productChanged = (e) => {
    var productType = $("#product_product_type").val();

    if (productType === "test") {
      $("#product-test-fields").show();
      $("#product-package-fields").hide();
    } else if (productType === "package") {
      $("#product-test-fields").hide();
      $("#product-package-fields").show();
    } else {
      $("#product-test-fields").hide();
      $("#product-package-fields").hide();
    }
  };

  $("#product_product_type").on("change", productChanged);

  productChanged();
});

$(document).on('turbolinks:load', () => {
  var currentValue = '';

  $('.box_requesting_physician-select2').select2({
    theme: 'bootstrap4',
    ajax: {
      url: '/bio_bank/boxes/requesting_physicians.json',
      dataType: 'json',
      data: (params) => {
        currentValue = params.term;

        return {
          q: {
            requesting_physician_cont: params.term
          }
        }
      },
      processResults: (data) => {
        var options = $.map(data.requesting_physicians, (item) => {
          return {
            id: item,
            text: item
          }
        });

        options.unshift({
          id: currentValue,
          text: currentValue
        })

        return {
          results: options,
          pagination: {
            more: (options.length == 25)
          }
        }
      }
    }
  })
})

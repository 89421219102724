$(document).on('turbolinks:load', () => {
  $('.patients-select2').select2({
    theme: 'bootstrap4',
    ajax: {
      url: '/patients.json',
      dataType: 'json',
      data: (params) => {
        return {
          q: {
            id_eq: params.term
          }
        }
      },
      processResults: (data) => {
        var options = $.map(data, (item) => {
          text = `${item.id} : ${item.last_name}, ${item.first_name} ${item.middle_name} : ${item.birth_date}`
          return {
            id: item.id,
            text: text
          }
        });

        return {
          results: options
        }
      }
    }
  })

  $('.associate-patient-use').on('click', (evt) => {
    evt.preventDefault()

    var patientData = $(evt.target).data('patient')

    for (var key in patientData) {
      var selectorKey = key
      var data = patientData[key]

      if(key == 'id') {
        selectorKey = 'patient_id'
      }

      if(key == 'home_phone_number') {
        data = (patientData['home_phone_number_country_code'] || '') +
               (patientData[key] || '')
      }
      else if(key == 'mobile_number') {
        data = (patientData['mobile_number_country_code'] || '') +
               (patientData[key] || '')
      }

      var selector = '[name="associate_covid_test_to_patient[' + selectorKey + ']"]';

      $(selector).val(data);
    }
  })
})

$(document).on("turbolinks:load", () => {
  $(".add-professional-specialty-button").on("click", (ev) => {
    ev.preventDefault();
    var lastSpecialtyField = $(
      'input[name="marketing_event_respondent[specialties][]"]:last-of-type',
    );
    var cloned = lastSpecialtyField.clone();
    cloned.val("");
    lastSpecialtyField.after(cloned);
    cloned.focus();
  });

  $(".add-professional-subspecialty-button").on("click", (ev) => {
    ev.preventDefault();
    var lastSubspecialtyField = $(
      'input[name="marketing_event_respondent[subspecialties][]"]:last-of-type',
    );
    var cloned = lastSubspecialtyField.clone();
    cloned.val("");
    lastSubspecialtyField.after(cloned);
    cloned.focus();
  });

  $(".select2-specializations").select2({
    theme: "bootstrap4",
    tags: true,
    tokenSeparators: [","],
  });

  $(".select2-primary-health-facility").select2({
    theme: "bootstrap4",
    tags: true,
  });
});

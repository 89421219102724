$(document).on("turbolinks:load", () => {
  var changeTransactionType = function (transactionType) {
    if (
      transactionType == "Cash Sales" ||
      transactionType == "Accounts Receivable (Cashier Created)"
    ) {
      $(".accounting_record_erp_transaction_id").show();
      $(".accounting_record_price").hide();
      $(".accounting_record_set_price_buttons").hide();
    } else if (transactionType == "Accounts Receivable") {
      $(".accounting_record_erp_transaction_id").hide();
      $(".accounting_record_price").show();
      $(".accounting_record_set_price_buttons").show();
    } else {
      $(".accounting_record_erp_transaction_id").hide();
      $(".accounting_record_price").hide();
      $(".accounting_record_set_price_buttons").hide();
    }
  };

  $("select#accounting_record_transaction_type").on("change", (evt) => {
    changeTransactionType($(evt.target).val());
  });

  $("a.accounting-record-set-price").on("click", (evt) => {
    evt.preventDefault();

    data = $(evt.target).data();

    $("#accounting_record_price").val(data.price);
  });

  changeTransactionType($("select#accounting_record_transaction_type").val());
});

$(document).on('turbolinks:load', () => {
  // PCR Run Form
  // * Show/Hide Additional Tests
  if($('select#pcr_run_pcr_type').length > 0) {
    var pcrRunPcrTypeChanged = (event) => {
      var pcrType = $('select#pcr_run_pcr_type').val()

      $('.additional_tests').hide()
      $('.additional_tests select').prop('disabled', true)

      $('.additional_tests-' + pcrType).show()
      $('.additional_tests-' + pcrType + ' select').prop('disabled', false)
    }

    $('select#pcr_run_pcr_type').on('change', pcrRunPcrTypeChanged)
    $('select#pcr_run_pcr_type').trigger('change')
  }

  // PCR Run Plate Well Results
  // * Show/Hide Repeat Reasons
  $('[name="result[outcome_current_state]"]').on('change', function(event) {
    var outcome = $('[name="result[outcome_current_state]"]:checked').val()

    if(outcome == 'repeat_extraction') {
      $('.repeat_extraction_reason').show()
      $('.repeat_extraction_reason select').prop('disabled', false)
    } else {
      $('.repeat_extraction_reason').hide()
      $('.repeat_extraction_reason select').prop('disabled', true)
    }

    if(outcome == 'repeat_pcr') {
      $('.repeat_pcr_reason').show()
      $('.repeat_pcr_reason select').prop('disabled', false)
    } else {
      $('.repeat_pcr_reason').hide()
      $('.repeat_pcr_reason select').prop('disabled', true)
    }
  })

  $('[name="result[outcome_current_state]"]:checked').trigger('change')

  // New PCR Run Form
  // Submits page to filter the pcr kits
  // by pcr type
  $('[name="pcr_run[pcr_type]"]').on('change', function(event) {
    $('#new_pcr_run').append(
      '<input type="hidden" name="pcr_type_changed" value="1" />'
    ).submit()
  })
})

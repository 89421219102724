$(document).on("turbolinks:load", () => {
  $('.auto-date').daterangepicker({
    singleDatePicker: true,
    autoUpdateInput: false,
    autoApply: true,
    locale: {
      format: 'YYYY-MM-DD'
    }
  })

  $('.auto-date').on('apply.daterangepicker', (ev, picker) => {
    $(ev.target).val(picker.startDate.format('YYYY-MM-DD'))
  })
})

$(document).on('turbolinks:load', () => {
  $('[name="plate_wells_send_for_repeat[repeat_type]"]').on('change', function(event) {
    var outcome = $('[name="plate_wells_send_for_repeat[repeat_type]"]:checked').val()

    if(outcome == 'repeat_extraction') {
      $('.repeat_extraction_reason').show()
      $('.repeat_extraction_reason select').prop('disabled', false)
    } else {
      $('.repeat_extraction_reason').hide()
      $('.repeat_extraction_reason select').prop('disabled', true)
    }

    if(outcome == 'repeat_pcr') {
      $('.repeat_pcr_reason').show()
      $('.repeat_pcr_reason select').prop('disabled', false)
    } else {
      $('.repeat_pcr_reason').hide()
      $('.repeat_pcr_reason select').prop('disabled', true)
    }
  })
  $('[name="plate_wells_send_for_repeat[repeat_type]"]:checked').trigger('change')
})

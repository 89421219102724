$(document).on('click', '.btn-store-storage-slot', (event) => {
  event.preventDefault()

  var data = $(event.currentTarget).data()
  var form = $('#new_store_box_to_storage_slot')

  $('#store_box_to_storage_slot_drawer').val(data.drawer)
  $('#store_box_to_storage_slot_column').val(data.column)
  $('#store_box_to_storage_slot_row').val(data.row)

  form.submit()
})

$(document).on('turbolinks:load', () => {
  $('.store-box-cabinet-select').select2({
    theme: 'bootstrap4'
  }).on('change', (e) => {
    var cabinetId = $(e.currentTarget).val()
    new_location = window.location.pathname + '?store_box_to_storage_slot[cabinet_id]=' + cabinetId;
    window.location = new_location;
  })
})

$(document).on("turbolinks:load", () => {
	var barangayHelper = $('.select2-barangay-helper')

  barangayHelper.select2({
    theme: 'bootstrap4',
    minimumInputLength: 3,
    ajax: {
      url: '/barangays.json',
      data: (params) => {
        var query = {
          q: {
            search_barangay: params.term
          },
          page: params.page || 1
        }

        return query;
      },
      processResults: (data) => {
        var selectOption = []
        $.each(data, (index, elem) => {
          selectOption.push({
            id: elem.id,
            text: [elem.name, elem.city, elem.province].join(', '),
            barangay: elem.name,
            city: elem.city,
            province: elem.province
          })
        });

        return {
          results: selectOption,
          pagination: {
            more: (selectOption.length == 100)
          }
        }
      }
    }
  })

  barangayHelper.on('select2:select', (e) => {
    var addressData = e.params.data;

    var data = $(e.target).data()

    $(data.barangaySelector).val(
      addressData.barangay
    );

    $(data.citySelector).val(
      addressData.city
    );

    $(data.provinceSelector).val(
      addressData.province
    )
  });
});

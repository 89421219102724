$(document).on("turbolinks:load", () => {
  $(".hubspot-products-select2").select2({
    theme: "bootstrap4",
    ajax: {
      url: "/ordering/hubspot_products.json",
      dataType: "json",
      delay: 250,
      processResults: (data) => {
        var options = $.map(data, (item) => {
          return {
            id: item.attributes.id,
            text: `${item.attributes.name} | PHP ${item.attributes.price}`,
            record: item.attributes,
          };
        });

        return {
          results: options,
        };
      },
    },
  });
});

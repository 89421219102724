// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start();
require("turbolinks").start();
require("./channels");
var wrap = require("word-wrap");

require("chartkick");
require("chart.js");
require("trix");
require("./overrides/trix_editor");
require("@nathanvda/cocoon");

require("./autos/barangay_helper");
require("./autos/date_picker");

require("./test_types");
require("./test_types/ihc_test_types_results");
require("./test_types/target_data_fields");
require("./patients");
require("./pcr_kits");
require("./pcr_runs");
require("./pcr_runs/begin_runs");
require("./documents");
require("./reports");
require("./organizations");

require("./bio_bank/box_requesting_physician_select2");
require("./bio_bank/store_box_to_storage_slot");

require("./physician_portal/pcr_records/pcr_run_selector");
require("./physician_portal/plate_wells/send_for_repeat");

require("./physicians/form");
require("./physicians/selector");

require("./accounting_records");
require("./fish_records/fish_images");
require("./service_orders/service_order_form");
require("./products/products_form");
require("./products/hubspot_products");

require("./health_facilities/selector");

require("./result_templates/editor");
require("./reusable_templates/editor");

require("./marketing_event_respondents/form");

window.jQuery = $;
window.$ = $;

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// Ransack Filter
$(document).on("turbolinks:load", () => {
  var covidTestReceivedAtRange = $("#q_specimen_received_at_between_dates");

  // GuardClause
  if (covidTestReceivedAtRange.length == 0) {
    return;
  }

  covidTestReceivedAtRange.daterangepicker({
    autoUpdateInput: false,
    ranges: {
      Today: [moment(), moment()],
      Yesterday: [moment().subtract(1, "days"), moment().subtract(1, "days")],
      "Last 7 Days": [moment().subtract(6, "days"), moment()],
      "Last 30 Days": [moment().subtract(29, "days"), moment()],
      "This Month": [moment().startOf("month"), moment().endOf("month")],
      "Last Month": [
        moment().subtract(1, "month").startOf("month"),
        moment().subtract(1, "month").endOf("month"),
      ],
    },
    alwaysShowCalendars: true,
  });

  covidTestReceivedAtRange.on("apply.daterangepicker", (ev, picker) => {
    covidTestReceivedAtRange.val(
      picker.startDate.format("MM/DD/YYYY") +
        " - " +
        picker.endDate.format("MM/DD/YYYY"),
    );
  });
});

// Ransack Filter
$(document).on("turbolinks:load", () => {
  var covidTestReleasedAtRange = $("#q_released_at_between_dates");

  // GuardClause
  if (covidTestReleasedAtRange.length == 0) {
    return;
  }

  covidTestReleasedAtRange.daterangepicker({
    autoUpdateInput: false,
    ranges: {
      Today: [moment(), moment()],
      Yesterday: [moment().subtract(1, "days"), moment().subtract(1, "days")],
      "Last 7 Days": [moment().subtract(6, "days"), moment()],
      "Last 30 Days": [moment().subtract(29, "days"), moment()],
      "This Month": [moment().startOf("month"), moment().endOf("month")],
      "Last Month": [
        moment().subtract(1, "month").startOf("month"),
        moment().subtract(1, "month").endOf("month"),
      ],
    },
    alwaysShowCalendars: true,
  });

  covidTestReleasedAtRange.on("apply.daterangepicker", (ev, picker) => {
    covidTestReleasedAtRange.val(
      picker.startDate.format("MM/DD/YYYY") +
        " - " +
        picker.endDate.format("MM/DD/YYYY"),
    );
  });
});

// Ransack Filter
$(document).on("turbolinks:load", () => {
  var caseArrivalDateRange = $("#q_case_arrival_date_between_dates");

  // GuardClause
  if (caseArrivalDateRange.length == 0) {
    return;
  }

  caseArrivalDateRange.daterangepicker({
    autoUpdateInput: false,
    ranges: {
      Today: [moment(), moment()],
      Yesterday: [moment().subtract(1, "days"), moment().subtract(1, "days")],
      "Last 7 Days": [moment().subtract(6, "days"), moment()],
      "Last 30 Days": [moment().subtract(29, "days"), moment()],
      "This Month": [moment().startOf("month"), moment().endOf("month")],
      "Last Month": [
        moment().subtract(1, "month").startOf("month"),
        moment().subtract(1, "month").endOf("month"),
      ],
    },
    alwaysShowCalendars: true,
  });

  caseArrivalDateRange.on("apply.daterangepicker", (ev, picker) => {
    caseArrivalDateRange.val(
      picker.startDate.format("MM/DD/YYYY") +
        " - " +
        picker.endDate.format("MM/DD/YYYY"),
    );
  });
});

$(document).on("turbolinks:load", () => {
  $(".auto-select2").select2({
    theme: "bootstrap4",
  });

  $("#link_covid_test_request_covid_test_request_id").select2({
    theme: "bootstrap4",
    ajax: {
      url: "/covid_test_requests.json",
      dataType: "json",
      data: (params) => {
        organizationId = $(
          "#link_covid_test_request_covid_test_request_id",
        ).data("organizationId");
        return {
          q: {
            patient_full_name_cont: params.term,
            organization_id_eq: organizationId,
          },
        };
      },
      processResults: (data) => {
        var options = $.map(data, (item) => {
          return {
            id: item.id,
            text: item.patient_full_name,
          };
        });

        return {
          results: options,
        };
      },
    },
  });
});

$(document).on("turbolinks:before-cache", () => {
  $(".auto-select2").select2("destroy");
});

$(document).on("turbolinks:load", () => {
  var collectionDateTimeInput = $(
    [
      "#covid_test_specimen_collected_at",
      "#covid_test_specimen_collection_specimen_collected_at",
      "#change_covid_test_released_timestamp_released_at",
    ].join(", "),
  );

  if (collectionDateTimeInput.length > 0) {
    collectionDateTimeInput
      .daterangepicker({
        timePicker: true,
        singleDatePicker: true,
        autoUpdateInput: false,
        locale: {
          format: "YYYY-MM-DD HH:mm",
        },
      })
      .on("apply.daterangepicker", (ev, picker) => {
        $(ev.target).val(picker.startDate.format("YYYY-MM-DD HH:mm"));
      });
  }

  var receivedDateTimeInput = $("#covid_test_specimen_received_at");
  if (receivedDateTimeInput.length > 0) {
    receivedDateTimeInput.daterangepicker({
      timePicker: true,
      singleDatePicker: true,
      locale: {
        format: "YYYY-MM-DD HH:mm",
      },
    });
  }

  var transactionReceiptReceivedDateTimeInput = $(
    "#transaction_receipt_received_at",
  );
  if (transactionReceiptReceivedDateTimeInput.length > 0) {
    transactionReceiptReceivedDateTimeInput.daterangepicker({
      timePicker: true,
      singleDatePicker: true,
      locale: {
        format: "YYYY-MM-DD HH:mm",
      },
    });
  }
});

$(document).on("turbolinks:load", () => {
  var transactionReceiptPrint = $("#transaction-receipt-print");
  if (transactionReceiptPrint.length == 0) {
    return;
  }

  setTimeout(function () {
    window.print();
    window.history.back();
  }, 500);
});

$(document).on("turbolinks:load", () => {
  var covidTestConfirmationPrint = $("#covid-test-print-confirmation");
  if (covidTestConfirmationPrint.length == 0) {
    return;
  }

  setTimeout(function () {
    window.print();
    window.history.back();
  }, 500);
});

$(document).on("turbolinks:load", () => {
  var historyExposureContacts = $("tbody.history-exposure-contacts");

  $(".add-history-exposure-contact").on("click", (event) => {
    event.preventDefault();

    var template = historyExposureContacts.find("tr").last().clone();
    template.find("input").val("");
    historyExposureContacts.append(template);
  });

  var additionalCloseContacts = $("tbody.additional-close-contacts");

  $(".add-additional-close-contact").on("click", (event) => {
    event.preventDefault();

    var template = additionalCloseContacts.find("tr").last().clone();
    template.find("input").val("");
    additionalCloseContacts.append(template);
  });

  var caseDatesSelector = [
    '[name*="[interview_date]"]',
    '[name*="[previous_covid_consultation_date]"]',
    '[name*="[disposition_discharge_date]"]',
    '[name*="[vaccination_date]"]',
    '[name*="[vaccination_2_date]"]',
    '[name*="[illness_onset_date]"]',
    '[name*="[chest_imaging_date]"]',
    '[name*="[patient_previous_positive_covid_date]"]',
    '[name*="[outcome_recovery_date]"]',
    '[name*="[outcome_death_date]"]',
    '[name*="[history_exposure_contact_date]"]',
    '[name*="[history_exposure_to_place_international_travel_date]"]',
    '[name*="[history_exposure_to_place_international_travel_end_date]"]',
    '[name*="[departure_date]"]',
    '[name*="[arrival_date]"]',
    '[name*="[history_exposure_to_place_health_facility_date]"]',
    '[name*="[history_exposure_to_place_health_facility_end_date]"]',
    '[name*="[history_exposure_to_place_closed_setting_date]"]',
    '[name*="[history_exposure_to_place_closed_setting_end_date]"]',
    '[name*="[history_exposure_to_place_school_date]"]',
    '[name*="[history_exposure_to_place_school_end_date]"]',
    '[name*="[history_exposure_to_place_work_place_date]"]',
    '[name*="[history_exposure_to_place_work_place_end_date]"]',
    '[name*="[history_exposure_to_place_market_date]"]',
    '[name*="[history_exposure_to_place_market_end_date]"]',
    '[name*="[history_exposure_to_place_social_gathering_date]"]',
    '[name*="[history_exposure_to_place_social_gathering_end_date]"]',
    '[name*="[history_exposure_to_place_others_date]"]',
    '[name*="[history_exposure_to_place_others_end_date]"]',
    '[name*="[history_covid_local_departure_date]"]',
    '[name*="[history_covid_local_arrival_date]"]',
    '[name*="[history_covid_local_2_departure_date]"]',
    '[name*="[history_covid_local_2_arrival_date]"]',
  ].join(",");

  $(caseDatesSelector).daterangepicker({
    singleDatePicker: true,
    autoUpdateInput: false,
    autoApply: true,
    locale: {
      format: "YYYY-MM-DD",
    },
  });

  $(caseDatesSelector).on("apply.daterangepicker", (ev, picker) => {
    $(ev.target).val(picker.startDate.format("YYYY-MM-DD"));
  });

  var caseTimestampsSelector = [
    '[name*="[disposition_admitted_in_hospital_timestamp]"]',
    '[name*="[disposition_admitted_in_quarantine_facility_timestamp]"]',
    '[name*="[disposition_home_quarantine_timestamp]"]',
  ].join(",");

  $(caseTimestampsSelector).daterangepicker({
    timePicker: true,
    singleDatePicker: true,
    autoUpdateInput: false,
    locale: {
      format: "YYYY-MM-DD HH:mm",
    },
  });

  $(caseTimestampsSelector).on("apply.daterangepicker", (ev, picker) => {
    $(ev.target).val(picker.startDate.format("YYYY-MM-DD HH:mm"));
  });
});

$(document).on("turbolinks:load", () => {
  $("#airport_validation_or_number, #airport_validation_owwa_info").on(
    "keyup",
    (event) => {
      var value = $(event.target).val();
      $("form.airport_validation input[type=submit]").attr(
        "disabled",
        value.trim().length == 0,
      );
    },
  );
});

$(document).on("turbolinks:load", () => {
  var dataFieldChangesHandler = (event) => {
    var orNumber = $("#pal_international_departures_or_number").val();
    var collectionSite = $(
      "#pal_international_departures_specimen_collection_site",
    ).val();
    var collectionDateTime = $(
      "#pal_international_departures_specimen_collected_at",
    ).val();

    var disabled =
      orNumber.trim().length == 0 ||
      collectionSite.trim().length == 0 ||
      collectionDateTime.trim().length == 0;

    $("form.pal_international_departures input[type=submit]").attr(
      "disabled",
      disabled,
    );

    if (collectionSite == "NAIA T2 North Wing") {
      $(
        '#pal_international_departures_test_type option[value="covid_19_rt_pcr"]',
      ).prop("disabled", true);
      $("#pal_international_departures_test_type").select2();
      $("#pal_international_departures_test_type")
        .val("covid_19_antigen")
        .trigger("change");
    } else {
      $(
        '#pal_international_departures_test_type option[value="covid_19_rt_pcr"]',
      ).prop("disabled", false);
      $("#pal_international_departures_test_type").trigger("change");
    }
  };

  $("#pal_international_departures_or_number").on(
    "keyup",
    dataFieldChangesHandler,
  );
  $("#pal_international_departures_specimen_collected_at").on(
    "keyup",
    dataFieldChangesHandler,
  );
  $("#pal_international_departures_specimen_collection_site").on(
    "change",
    dataFieldChangesHandler,
  );
});

$(document).on("turbolinks:load", () => {
  var dataFieldChangesHandler = (event) => {
    var orNumber = $("#pal_domestic_departures_or_number").val();
    var collectionSite = $(
      "#pal_domestic_departures_specimen_collection_site",
    ).val();
    var collectionDateTime = $(
      "#pal_domestic_departures_specimen_collected_at",
    ).val();

    var disabled =
      orNumber.trim().length == 0 ||
      collectionSite.trim().length == 0 ||
      collectionDateTime.trim().length == 0;

    $("form.pal_domestic_departures input[type=submit]").attr(
      "disabled",
      disabled,
    );

    if (collectionSite == "NAIA T2 North Wing") {
      $(
        '#pal_domestic_departures_test_type option[value="covid_19_rt_pcr"]',
      ).prop("disabled", true);
      $("#pal_domestic_departures_test_type").select2();
      $("#pal_domestic_departures_test_type")
        .val("covid_19_antigen")
        .trigger("change");
    } else {
      $(
        '#pal_domestic_departures_test_type option[value="covid_19_rt_pcr"]',
      ).prop("disabled", false);
      $("#pal_domestic_departures_test_type").trigger("change");
    }
  };

  $("#pal_domestic_departures_or_number").on("keyup", dataFieldChangesHandler);
  $("#pal_domestic_departures_specimen_collected_at").on(
    "keyup",
    dataFieldChangesHandler,
  );
  $("#pal_domestic_departures_specimen_collection_site").on(
    "change",
    dataFieldChangesHandler,
  );
});

$(document).on("click", ".print-barcode", (event) => {
  event.preventDefault();

  var prioritiesLabel = {
    high: "HIGH",
    priority: "PRIO",
    express: "EXPR",
    super_express: "SXPR",
  };

  var data = $(event.target).data();

  JSPM.JSPrintManager.auto_reconnect = true;
  JSPM.JSPrintManager.start();
  JSPM.JSPrintManager.WS.onStatusChanged = () => {
    if (JSPM.JSPrintManager.websocket_status == JSPM.WSStatus.Open) {
      var job = new JSPM.ClientPrintJob();
      job.clientPrinter = new JSPM.InstalledPrinter("Godex EZ120");

      var patientFullNames = wrap(data.patientFullName, {
        width: 23,
        indent: "",
        trim: true,
      })
        .split("\n")
        .slice(0, 2);
      var yPosition = 56;
      var nameHeight = 22;

      var priorityLabel = prioritiesLabel[data.priority];

      commands = [];

      commands.push("^W90");
      commands.push("^Q23,0,0");
      commands.push("^E40");
      commands.push("^L");

      commands.push("BQ,12,0,1,5,30,0,0," + data.barcode);
      commands.push("AB,12,30,1,1,0,0," + data.specimenNumber);
      $.each(patientFullNames, (index, value) => {
        commands.push("AA,12," + yPosition + ",1,1,0,0," + value);
        yPosition += nameHeight;
      });
      commands.push("AA,20," + yPosition + ",1,1,0,0," + data.patientBirthDate);
      if (priorityLabel) {
        commands.push(
          "AB,130," + (yPosition - 4) + ",1,1,4,0I, " + priorityLabel + " ",
        );
      }

      commands.push("BQ,252,0,1,5,30,0,0," + data.barcode);
      commands.push("AB,252,30,1,1,0,0," + data.specimenNumber);
      yPosition = 56;
      $.each(patientFullNames, (index, value) => {
        commands.push("AA,252," + yPosition + ",1,1,0,0," + value);
        yPosition += nameHeight;
      });
      commands.push(
        "AA,260," + yPosition + ",1,1,0,0," + data.patientBirthDate,
      );
      if (priorityLabel) {
        commands.push(
          "AB,370," + (yPosition - 4) + ",1,1,4,0I, " + priorityLabel + " ",
        );
      }

      commands.push("BQ,492,0,1,5,30,0,0," + data.barcode);
      commands.push("AB,492,30,1,1,0,0," + data.specimenNumber);
      yPosition = 56;
      $.each(patientFullNames, (index, value) => {
        commands.push("AA,492," + yPosition + ",1,1,0,0," + value);
        yPosition += nameHeight;
      });
      commands.push(
        "AA,500," + yPosition + ",1,1,0,0," + data.patientBirthDate,
      );
      if (priorityLabel) {
        commands.push(
          "AB,610," + (yPosition - 4) + ",1,1,4,0I, " + priorityLabel + " ",
        );
      }

      commands.push("E");
      commands.push("");

      job.printerCommands = commands.join("\n");
      job.sendToClient();
    }
  };
});

$(document).on("turbolinks:load", () => {
  var barangayMagic = $(".select2-barangay-magic");
  barangayMagic.select2({
    theme: "bootstrap4",
    minimumInputLength: 3,
    ajax: {
      url: "/clients/barangays.json",
      data: (params) => {
        var query = {
          q: {
            search_barangay: params.term,
          },
          page: params.page || 1,
        };

        return query;
      },
      processResults: (data) => {
        var selectOption = [];
        $.each(data, (index, elem) => {
          selectOption.push({
            id: elem.id,
            text: [elem.name, elem.city, elem.province].join(", "),
            barangay: elem.name,
            city: elem.city,
            province: elem.province,
          });
        });

        return {
          results: selectOption,
          pagination: {
            more: selectOption.length == 100,
          },
        };
      },
    },
  });

  barangayMagic.on("select2:select", (e) => {
    var addressData = e.params.data;

    $("#covid_test_patient_barangay").val(addressData.barangay);

    $("#covid_test_patient_city").val(addressData.city);

    $("#covid_test_patient_province").val(addressData.province);
  });
});

$(document).on("turbolinks:load", () => {
  var croppie;

  $("#patient-photo-camera").on("click", (event) => {
    $("#patient-photo-selector").click();
  });

  $("#patient-photo-save").on("click", (event) => {
    $("#patient-photo-save").attr("disabled", true);

    croppie
      .croppie("result", {
        type: "base64",
        size: "viewport",
        format: "jpeg",
      })
      .then((data) => {
        $("#covid_test_patient_photo").val(data);
        $("#covid-test-patient-photo-form").submit();
      });
  });

  $("#patient-photo-selector").on("change", (event) => {
    var selectedFile = event.target.files[0];
    var reader = new FileReader();

    reader.onload = (event) => {
      if (croppie) {
        croppie.croppie("destroy");
      }

      $(".patient-photo-editor").attr("src", event.target.result);
      croppie = $(".patient-photo-editor").croppie({
        enableOrientation: true,
        viewport: {
          width: 345,
          height: 345,
        },
        boundary: {
          width: 460,
          height: 460,
        },
      });

      $("#patient-photo-save").show();
    };

    reader.readAsDataURL(selectedFile);
  });
});

$(document).on("turbolinks:load", () => {
  var pesoPayPaymentForm = $("#pesoPayPaymentForm");

  if (pesoPayPaymentForm.length > 0) {
    setTimeout(() => {
      pesoPayPaymentForm.first().submit();
    }, 3000);
  }
});

$(document).on("turbolinks:load", () => {
  if (typeof Prism != "undefined") {
    Prism.highlightAll();
  }
});

// Order Ransack
$(document).on("turbolinks:load", () => {
  var ordersCreatedAtRange = $("#q_created_at_between_dates");

  // GuardClause
  if (ordersCreatedAtRange.length == 0) {
    return;
  }

  ordersCreatedAtRange.daterangepicker({
    autoUpdateInput: false,
    ranges: {
      Today: [moment(), moment()],
      Yesterday: [moment().subtract(1, "days"), moment().subtract(1, "days")],
      "Last 7 Days": [moment().subtract(6, "days"), moment()],
      "Last 30 Days": [moment().subtract(29, "days"), moment()],
      "This Month": [moment().startOf("month"), moment().endOf("month")],
      "Last Month": [
        moment().subtract(1, "month").startOf("month"),
        moment().subtract(1, "month").endOf("month"),
      ],
    },
    alwaysShowCalendars: true,
  });

  ordersCreatedAtRange.on("apply.daterangepicker", (ev, picker) => {
    ordersCreatedAtRange.val(
      picker.startDate.format("MM/DD/YYYY") +
        " - " +
        picker.endDate.format("MM/DD/YYYY"),
    );
  });
});

$(document).on("turbolinks:load", () => {
  $("#batch_covid_test_specimen_collected_at").daterangepicker({
    timePicker: true,
    singleDatePicker: true,
    minDate: moment().subtract(1, "day").format("YYYY-MM-DD HH:mm"),
    maxDate: moment().format("YYYY-MM-DD HH:mm"),
    locale: {
      format: "YYYY-MM-DD HH:mm",
    },
  });
});

$(document).on("turbolinks:load", () => {
  $(
    "#q_transaction_receipt_created_at_between_dates, #q_batch_created_at_between_dates, #q_order_created_at_between_dates",
  )
    .daterangepicker({
      autoUpdateInput: false,
      ranges: {
        Today: [moment(), moment()],
        Yesterday: [moment().subtract(1, "days"), moment().subtract(1, "days")],
        "Last 7 Days": [moment().subtract(6, "days"), moment()],
      },
      alwaysShowCalendars: true,
      maxSpan: {
        days: 31,
      },
      locale: {
        format: "YYYY-MM-DD",
      },
    })
    .on("apply.daterangepicker", (ev, picker) => {
      $(ev.target).val(
        picker.startDate.format("YYYY-MM-DD") +
          " - " +
          picker.endDate.format("YYYY-MM-DD"),
      );
    });
});

$(document).on("turbolinks:load", () => {
  // Case Editing
  $("#btn-case-copy-to-current-address").on("click", (ev) => {
    var covidTestAddressFieldsExist =
      $('input[name="covid_test[patient_barangay]"]').length > 0;

    if (covidTestAddressFieldsExist) {
      // DO NOT USE data, but use the fields that are on the same page.
      var targetMap = [
        [
          'input[name="covid_test[case_attributes][current_address_house_number]"]',
          'input[name="covid_test[patient_address_street_number]"]',
        ],
        [
          'input[name="covid_test[case_attributes][current_address_street]"]',
          'input[name="covid_test[patient_address]"]',
        ],
        [
          'input[name="covid_test[case_attributes][current_address_barangay]"]',
          'input[name="covid_test[patient_barangay]"]',
        ],
        [
          'input[name="covid_test[case_attributes][current_address_city]"]',
          'input[name="covid_test[patient_city]"]',
        ],
        [
          'input[name="covid_test[case_attributes][current_address_province]"]',
          'input[name="covid_test[patient_province]"]',
        ],
        [
          'input[name="covid_test[case_attributes][current_home_phone_number]"]',
          'input[name="covid_test[patient_contact_number]"]',
        ],
        [
          'input[name="covid_test[case_attributes][current_mobile_number]"]',
          'input[name="covid_test[patient_mobile_number]"]',
        ],
        [
          'input[name="covid_test[case_attributes][current_email]"]',
          'input[name="covid_test[patient_email]"]',
        ],
      ];

      $.each(targetMap, (index, map) => {
        $(map[0]).val($(map[1]).val());
      });
    } else {
      var data = $(ev.target).data();
      $("#case_current_address_house_number").val(
        data.currentAddressHouseNumber,
      );
      $("#case_current_address_street").val(data.currentAddressStreet);
      $("#case_current_address_barangay").val(data.currentAddressBarangay);
      $("#case_current_address_city").val(data.currentAddressCity);
      $("#case_current_address_province").val(data.currentAddressProvince);
      $("#case_current_home_phone_number").val(data.currentHomePhoneNumber);
      $("#case_current_mobile_number").val(data.currentMobileNumber);
      $("#case_current_email").val(data.currentEmail);
    }
  });
});

$(document).on("turbolinks:load", () => {
  var barangayMagic = $(".select2-generic-barangay-magic");
  barangayMagic.select2({
    theme: "bootstrap4",
    minimumInputLength: 3,
    ajax: {
      url: "/barangays.json",
      data: (params) => {
        var query = {
          q: {
            search_barangay: params.term,
          },
          page: params.page || 1,
        };

        return query;
      },
      processResults: (data) => {
        var selectOption = [];
        $.each(data, (index, elem) => {
          selectOption.push({
            id: elem.id,
            text: [elem.name, elem.city, elem.province].join(", "),
            barangay: elem.name,
            city: elem.city,
            province: elem.province,
          });
        });

        return {
          results: selectOption,
          pagination: {
            more: selectOption.length == 100,
          },
        };
      },
    },
  });

  barangayMagic.on("select2:select", (e) => {
    var addressData = e.params.data;

    var data = barangayMagic.data();

    $("#" + data.barangayTarget).val(addressData.barangay);

    $("#" + data.cityTarget).val(addressData.city);

    $("#" + data.provinceTarget).val(addressData.province);
  });
});

$(document).on("turbolinks:load", () => {
  $(".cart-item-test-type").on("change", (evt) => {
    $(evt.target).closest("form").submit();
  });
});

$(document).on("turbolinks:load", () => {
  $(".cart-item-product-id").on("change", (evt) => {
    $(evt.target).closest("form").submit();
  });
});

$(document).on("turbolinks:load", () => {
  $('input[name="one_health_pass_payment[service_time_slot]"]').on(
    "change",
    (evt) => {
      $("#select_early_swab").attr("disabled", false);
    },
  );
});

$(document).on("turbolinks:load", () => {
  $('[data-toggle="tooltip"]').tooltip();
});

$(document).on("turbolinks:load", () => {
  $('[name*="[scheduled_date]"]').daterangepicker({
    singleDatePicker: true,
    autoUpdateInput: false,
    autoApply: true,
    locale: {
      format: "YYYY-MM-DD",
    },
  });

  $('[name*="[scheduled_date]"]').on("apply.daterangepicker", (ev, picker) => {
    $(ev.target).val(picker.startDate.format("YYYY-MM-DD"));
  });
});

$(document).on("turbolinks:load", () => {
  $("#plate-well-modal").on("show.bs.modal", function (event) {
    var modal = $(this);
    var button = $(event.relatedTarget);

    var positionX = button.data("positionX");
    var positionY = button.data("positionY");

    modal
      .find(".modal-title")
      .text("Add Specimen to Well " + positionY + positionX);
    modal.find("#plate_well_position_y").val(positionY);
    modal.find("#plate_well_position_x").val(positionX);
  });

  $("#plate-well-modal").on("shown.bs.modal", function (event) {
    $("#plate_well_specimen_id").focus();
  });
});

// GET EFFING POPPLER TO WORK!
$(document).on("turbolinks:load", () => {
  var popoverTriggerList = [].slice.call(
    document.querySelectorAll('[data-toggle="popover"]'),
  );
  var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
    return new bootstrap.Popover(popoverTriggerEl);
  });

  var tooltipTriggerList = [].slice.call(
    document.querySelectorAll('[data-toggle="tooltip"]'),
  );
  var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
    return new bootstrap.Tooltip(tooltipTriggerEl);
  });

  $(".move-plate-well").popover({
    trigger: "click",
    html: true,
    content: function () {
      var button = $(this);
      var data = button.data();

      var content = "";
      content +=
        ' <a href="/pcr_runs/:pcr_run_id/plate_wells/:plate_well_id/move_left" class="btn btn-primary move-left ' +
        (data["left"] ? "" : "disabled") +
        '"><i class="fas fa-arrow-left"></i></a>';
      content +=
        ' <a href="/pcr_runs/:pcr_run_id/plate_wells/:plate_well_id/move_up" class="btn btn-primary move-up ' +
        (data["up"] ? "" : "disabled") +
        '"><i class="fas fa-arrow-up"></i></a>';
      content +=
        ' <a href="/pcr_runs/:pcr_run_id/plate_wells/:plate_well_id/move_down" class="btn btn-primary move-down ' +
        (data["down"] ? "" : "disabled") +
        '"><i class="fas fa-arrow-down"></i></a>';
      content +=
        ' <a href="/pcr_runs/:pcr_run_id/plate_wells/:plate_well_id/move_right" class="btn btn-primary move-right ' +
        (data["right"] ? "" : "disabled") +
        '"><i class="fas fa-arrow-right"></i></a> ';

      content = content.replaceAll(/:pcr_run_id/g, data.pcrRunId);
      content = content.replaceAll(/:plate_well_id/g, data.plateWellId);

      return content;
    },
  });
});

$(document).on("turbolinks:load", () => {
  $('[name="resolve_referral[result]"]').on("change", function (event) {
    var result = $('[name="resolve_referral[result]"]:checked').val();

    if (result == "repeat_extraction") {
      $(".repeat_extraction_reason").show();
      $(".repeat_extraction_reason select").prop("disabled", false);
    } else {
      $(".repeat_extraction_reason").hide();
      $(".repeat_extraction_reason select").prop("disabled", true);
    }

    if (result == "repeat_pcr") {
      $(".repeat_pcr_reason").show();
      $(".repeat_pcr_reason select").prop("disabled", false);
    } else {
      $(".repeat_pcr_reason").hide();
      $(".repeat_pcr_reason select").prop("disabled", true);
    }
  });

  $('[name="resolve_referral[result]"]:checked').trigger("change");
});

$(document).on("turbolinks:load", () => {
  const canvas = document.getElementById("signature-pad");

  if (canvas) {
    function resizeCanvas() {
      const ratio = Math.max(window.devicePixelRatio || 1, 1);
      canvas.width = canvas.offsetWidth * ratio;
      canvas.height = canvas.offsetHeight * ratio;
      canvas.getContext("2d").scale(ratio, ratio);
      signaturePad.clear(); // otherwise isEmpty() might return incorrect value
    }

    const signaturePad = new SignaturePad(canvas);

    window.addEventListener("resize", resizeCanvas);
    resizeCanvas();

    document
      .getElementById("signature-pad-clear")
      .addEventListener("click", (event) => {
        event.preventDefault();

        signaturePad.clear();
      });

    document
      .getElementById("signature-submit")
      .addEventListener("click", (event) => {
        event.preventDefault();

        var data = signaturePad.toDataURL();

        document.getElementById("user_signature_data").value = data;
        $("form.edit_user").submit();
      });
  }
});

$(document).on("turbolinks:load", () => {
  $('a[data-toggle="tab"]').on("shown.bs.tab", function (e) {
    history.pushState({}, "", e.target.hash);
  });

  var hash = document.location.hash;
  var prefix = "tab_";
  if (hash) {
    $('.nav-tabs a[href="' + hash.replace(prefix, "") + '"]').tab("show");
  }
});

$(document).on('turbolinks:load', () => {
  tinymce.init({
    selector: '.result-template-html',
    height: 600,
    plugins: 'code table',
    invalid_styles: {
      'table': 'height',
      'tr': 'height',
      'th': 'height',
      'td': 'height',
    },
    toolbar: [
      { name: 'history',     items: ['undo', 'redo'] },
      { name: 'styles',      items: ['styles'] },
      { name: 'formatting',  items: ['fontsize', 'bold', 'italic', 'underline'] },
      { name: 'alignment',   items: ['alignleft', 'aligncenter', 'alignright', 'alignjustify'] },
      { name: 'indentation', items: ['outdent', 'indent'] },
      { name: 'table',       items: ['table', 'hr'] },
      { name: 'visual',      items: ['code'] },
    ],
    table_toolbar: 'tableprops tableclass tabledelete | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol',
    table_appearance_options: false,
    table_border_styles: [
      { title: 'None', value: 'none' },
      { title: 'Solid', value: 'solid' },
      { title: 'Dotted', value: 'dotted' },
      { title: 'Dashed', value: 'dashed' },
      { title: 'Double', value: 'double' },
      { title: 'Hidden', value: 'hidden' },
    ],
    table_class_list: [
      { title: 'Layout (Hidden Borders)', value: 'table-layout' },
    ]
  })
})

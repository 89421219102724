import { basicSetup } from "codemirror";
import { EditorState } from "@codemirror/state";
import { EditorView, keymap } from "@codemirror/view";
import { defaultKeymap } from "@codemirror/commands";
import { indentWithTab } from "@codemirror/commands";
import { liquid } from "@codemirror/lang-liquid";

$(document).on("turbolinks:load", () => {
  const editorSource = document.querySelector("#result_template_template_html");
  const editorTarget = document.querySelector("#template-html-editor");

  if (editorSource != null && editorTarget != null) {
    const submitButton = document.querySelector("#result-template-submit");
    const syncEditor = () => {
      editorSource.value = editor.state.sliceDoc(0);
    };

    editorSource.parentNode.setAttribute("hidden", "true");

    let startState = EditorState.create({
      doc: editorSource.value,
      readOnly: true,
      extensions: [
        keymap.of([defaultKeymap, indentWithTab]),
        basicSetup,
        liquid(),
      ],
    });

    let editor = new EditorView({
      state: startState,
      parent: editorTarget,
    });

    submitButton.addEventListener("click", syncEditor);
  }
});

$(document).on("turbolinks:load", () => {
  $(".organizations-select2").select2({
    theme: "bootstrap4",
    ajax: {
      url: "/organizations.json",
      dataType: "json",
      data: (params) => {
        return {
          q: {
            name_cont: params.term,
          },
        };
      },
      processResults: (data) => {
        var options = $.map(data, (item) => {
          return {
            id: item.id,
            text: item.name,
            record: item,
          };
        });

        options.unshift({
          id: "",
          text: "[All Organizations]",
        });

        return {
          results: options,
        };
      },
    },
  });

  $(".sales-referrer-organizations-select2").select2({
    theme: "bootstrap4",
    ajax: {
      url: "/organizations.json",
      dataType: "json",
      data: (params) => {
        return {
          q: {
            sales_referrer_eq: "true",
            name_cont: params.term,
          },
        };
      },
      processResults: (data) => {
        var options = $.map(data, (item) => {
          return {
            id: item.id,
            text: item.name,
          };
        });

        options.unshift({
          id: "",
          text: "[All Sales Referrer Organizations]",
        });

        return {
          results: options,
        };
      },
    },
  });
});

$(document).on("turbolinks:before-cache", () => {
  $(".organizations-select2").select2("destroy");
  $(".sales-referrer-organizations-select2").select2("destroy");
});

$(document).on('turbolinks:load', () => {
  // Begin PCR Run Form
  // Submits page to see if the pcr machine has module_block
  // enabled, but prevents from saving.
  $('[name="pcr_run_begin_run[pcr_machine_id]"]').on('change', function(event) {
    var pcrMachineId = $('[name="pcr_run_begin_run[pcr_machine_id]"]:checked').val()

    $('#new_pcr_run_begin_run').append(
      '<input type="hidden" name="pcr_machine_changed" value="1" />'
    ).submit()
  })
})

$(document).on("turbolinks:load", () => {
  $("input.fish_image_representative").on("change", (evt) => {
    var fishRecordId = $(evt.target).data("fishRecordId");
    var fishImageId = $(evt.target).data("fishImageId");
    var checked = $(evt.target).prop("checked");

    var url =
      "/physician_portal/fish_records/" +
      fishRecordId +
      "/fish_images/" +
      fishImageId +
      "/set_representative";

    $.ajax({
      url: url,
      method: "POST",
      dataType: "json",
      data: {
        fish_image: {
          representative: checked,
        },
      },
      error: (xhr, status, error) => {
        alert("Updating representative failed. Refreshing page.");
        window.location.reload();
      },
      success: (data, status, xhr) => {
        var fishImage = data;
        var badgeId = "#fish_image_" + fishImage.id + "_representative_badge";
        if (fishImage.representative) {
          $(badgeId).show();
        } else {
          $(badgeId).hide();
        }
      },
    });
  });
});

$(document).on('turbolinks:load', () => {
  $('.physician-selector').select2({
    theme: 'bootstrap4',
    ajax: {
      url: '/physicians.json',
      dataType: 'json',
      data: (params) => {
        return {
          q: {
            search_by_full_name: params.term
          }
        }
      },
      processResults: (data) => {
        var options = $.map(data, (item) => {
          text = `${item.id} : Dr. ${item.first_name} ${item.middle_name} ${item.last_name} (${item.workflow_state})`
          return {
            id: item.id,
            text: text
          }
        });

        options.unshift(
          {
            id: '',
            text: '[No Physician Selected]'
          }
        );

        return {
          results: options
        }
      }
    }
  })
})
